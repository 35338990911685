// redux/slices/formBuilderSlice.js

import { createSlice } from '@reduxjs/toolkit';
import { arrayMove } from '@dnd-kit/sortable'; // Ensure arrayMove is imported

const initialState = {
  moduleValue: "",
  functionValue: "",
  formType: "UI",
  canvasItems: [],
  selectedItem: null,
  data_source_tables: [], // Updated from rows
  table_mappings: [], // Updated from mappings
  column_mappings: [], // Updated from columnsRows
  activeTab: 1,
  selectedDataAPIOption: "",
  fragments: [], // For GraphQL fragments
  queries: [], // For GraphQL queries
  mutations: [], // For GraphQL mutations
  selectedOperation: null,
};

const formBuilderSlice = createSlice({
  name: 'formBuilder',
  initialState,
  reducers: {
    setFormBuilderData: (state, action) => {

      // Generic setter for top-level fields
      Object.assign(state, action.payload);
    },
    setSelectedItem: (state, action) => {
      state.selectedItem = action.payload;
    },
    updateCanvasItems: (state, action) => {
      const { id, updatedItem } = action.payload;
      const safeParse = (json) => {
        try {
          const parsed = JSON.parse(json);
          return parsed;
        } catch (e) {
          return [];
        }
      };

      const updateNestedItemInTabs = (tabs, itemId, updatedItem) => {
        const updatedTabs = tabs.map((tab) => {
          const updatedContent = tab.content.map((contentItem) => {
            if (contentItem.id === itemId) {
              const newContentItem = {
                ...contentItem,
                ...updatedItem,
                groupedProperties: (updatedItem.groupedProperties || []).map((group) => ({
                  ...group,
                  properties: group.properties.map((prop) => ({ ...prop })),
                })),
              };
              return newContentItem;
            }
            return contentItem;
          });

          const newTab = {
            ...tab,
            content: updatedContent,
          };
          return newTab;
        });

        return updatedTabs;
      };

      const updateCanvasItemsRecursively = (items, parentId, parentType, itemId, updatedItem) => {
        return items.map((item) => {

          // Direct match for updating the item
          if (item.id === itemId) {
            return {
              ...item,
              ...updatedItem,
              groupedProperties: (updatedItem.groupedProperties || []).map((group) => ({
                ...group,
                properties: group.properties.map((prop) => ({ ...prop })),
              })),
            };
          }

          // Parent match with tabs nested under content inside groupedProperties
          if (item.id === parentId && item.type === parentType) {
            const newGroupedProperties = item.groupedProperties.map((group) => {
              const updatedProperties = group.properties.map((prop) => {
                if (prop.name === "tabs" && prop.value) {
                  const tabs = safeParse(prop.value); // Parse the tabs inside content
                  const updatedTabs = updateNestedItemInTabs(tabs, itemId, updatedItem);
                  const updatedProp = {
                    ...prop,
                    value: JSON.stringify(updatedTabs),
                  };
                  return updatedProp;
                }
                return prop;
              });

              return {
                ...group,
                properties: updatedProperties,
              };
            });

            const newItem = {
              ...item,
              groupedProperties: newGroupedProperties,
            };
            return newItem;
          }
          return item;
        });
      };


      state.canvasItems = updateCanvasItemsRecursively(
        state.canvasItems,
        updatedItem.parent_id,
        updatedItem.parent_element_type,
        id,
        updatedItem
      );

    },




    addCanvasItem: (state, action) => {
      // Add a new item to canvasItems
      const newItem = action.payload;
      state.canvasItems.push(newItem);
    },
    removeCanvasItem: (state, action) => {
      const itemId = action.payload;
      state.canvasItems = state.canvasItems.filter((item) => item.id !== itemId);

      // Remove from any tabs
      const removeItemFromTabs = (list, itemId) =>
        list.map((item) => {
          if (item.type === "tabs") {
            const tabs = Array.isArray(item.tabs) ? item.tabs : JSON.parse(item.tabs || '[]');
            tabs.forEach(tab => {
              tab.content = tab.content.filter(child => child.id !== itemId);
            });
            return { ...item, tabs };
          }
          return item;
        });

      state.canvasItems = removeItemFromTabs(state.canvasItems, itemId);
    },
    updateDataSourceTables: (state, action) => {
      state.data_source_tables = action.payload;
    },
    updateTableMappings: (state, action) => {
      state.table_mappings = action.payload;
    },
    updateColumnMappings: (state, action) => {
      state.column_mappings = action.payload;
    },
    addTableMapping: (state, action) => {
      // Add a new table mapping
      state.table_mappings.push(action.payload);
    },
    removeTableMapping: (state, action) => {
      // Remove a table mapping by ID
      state.table_mappings = state.table_mappings.filter(mapping => mapping.table_mapping_id !== action.payload);
    },
    updateTableMapping: (state, action) => {
      const { table_mapping_id, updates } = action.payload;
      const index = state.table_mappings.findIndex(mapping => mapping.table_mapping_id === table_mapping_id);
      if (index !== -1) {
        state.table_mappings[index] = { ...state.table_mappings[index], ...updates };
      }
    },
    addColumnMapping: (state, action) => {
      // Add a new column mapping
      state.column_mappings.push(action.payload);
    },
    removeColumnMapping: (state, action) => {
      // Remove a column mapping by ID
      state.column_mappings = state.column_mappings.filter(mapping => mapping.column_mapping_id !== action.payload);
    },
    updateColumnMapping: (state, action) => {
      const { column_mapping_id, updates } = action.payload;
      const index = state.column_mappings.findIndex(mapping => mapping.column_mapping_id === column_mapping_id);
      if (index !== -1) {
        state.column_mappings[index] = { ...state.column_mappings[index], ...updates };
      }
    },
    updateTabData: (state, action) => {
      const { tabIndex } = action.payload;
      state.activeTab = tabIndex;
    },
    setSelectedDataAPIOption: (state, action) => {
      state.selectedDataAPIOption = action.payload;
    },
    reorderCanvasItems: (state, action) => {
      const { oldIndex, newIndex } = action.payload;
      state.canvasItems = arrayMove(state.canvasItems, oldIndex, newIndex);
    },
    moveItemToMainCanvas: (state, action) => {
      const itemId = action.payload;
      let movedItem = null;
      state.canvasItems = state.canvasItems.filter(item => {
        if (item.id === itemId) {
          movedItem = { ...item, parent_id: null, parent_element_type: "canvas" }; // Update parent info
          return false;
        }
        return true;
      });
      if (movedItem) {
        state.canvasItems.push(movedItem);
      }
    },
    moveItemToTab: (state, action) => {
      const { itemId, parentId, tabIndex, newItemFromPalette, draggedItem } = action.payload; // Added `draggedItem`
      let movedItem = null;
      // Case 1: Adding a new item from the palette
      if (newItemFromPalette && draggedItem) {
        movedItem = {
          ...draggedItem,
          id: `${draggedItem.id}-${Date.now()}`, // Generate a unique ID for the canvas
          parent_id: parentId,
          parent_element_type: "tabs",
        };
      } else {
        // Case 2: Moving an existing item within or between tabs
        state.canvasItems = state.canvasItems.map(item => {
          if (item.type === "tabs") {
            const tabsProperty = item.groupedProperties
              ?.find(group => group.groupName.toLowerCase() === "content")
              ?.properties.find(prop => prop.name === "tabs" && prop.type === "json");

            const tabs = tabsProperty
              ? JSON.parse(tabsProperty.value || '[]')
              : [];

            tabs.forEach((tab, idx) => {
              if (!Array.isArray(tab.content)) {
                tab.content = []; // Ensure content is always an array
              }

              const index = tab.content.findIndex(child => child.id === itemId);

              if (index !== -1) {
                movedItem = {
                  ...tab.content.splice(index, 1)[0],
                  parent_id: parentId,
                  parent_element_type: "tabs",
                };
              }
            });

            if (tabsProperty) {
              tabsProperty.value = JSON.stringify(tabs);
            }
            return { ...item, groupedProperties: [...item.groupedProperties] };
          }

          // Remove item from main canvas
          if (item.id === itemId) {
            movedItem = { ...item, parent_id: parentId, parent_element_type: "tabs" };
            return null; // Remove item from main canvas
          }

          return item;
        }).filter(Boolean); // Remove nulls from canvasItems
      }


      // Add the item to the specified tab
      if (movedItem) {
        state.canvasItems = state.canvasItems.map(item => {
          if (item.id === parentId && item.type === "tabs") {
            const tabsProperty = item.groupedProperties
              ?.find(group => group.groupName.toLowerCase() === "content")
              ?.properties.find(prop => prop.name === "tabs" && prop.type === "json");

            const tabs = tabsProperty
              ? JSON.parse(tabsProperty.value || '[]')
              : [];

            if (tabIndex >= 0 && tabIndex < tabs.length) {
              if (!Array.isArray(tabs[tabIndex].content)) {
                tabs[tabIndex].content = []; // Ensure content is initialized
              }
              tabs[tabIndex].content.push(movedItem); // Add item to the tab
            }

            if (tabsProperty) {
              tabsProperty.value = JSON.stringify(tabs);
            }
            return { ...item, groupedProperties: [...item.groupedProperties] };
          }
          return item;
        });

      } else {
        console.error('No item found to move.');
      }
    },


    // GraphQL Operation Actions
    addGraphQLOperation: (state, action) => {
      const { operationType, operation } = action.payload; // operationType: 'fragments' | 'queries' | 'mutations' | 'data_source_tables' | 'table_mappings' | 'column_mappings'
      if (state[operationType]) {
        state[operationType].push(operation);
      } else {
        console.error(`Unknown operation type: ${operationType}`);
      }
    },
    editGraphQLOperation: (state, action) => {
      const { operationType, id, updatedOperation } = action.payload;
      // Determine the correct ID field based on operationType
      let idField = 'id';
      if (operationType === 'data_source_tables') {
        idField = 'table_id';
      } else if (operationType === 'table_mappings') {
        idField = 'table_mapping_id';
      } else if (operationType === 'column_mappings') {
        idField = 'column_mapping_id';
      }

      const index = state[operationType].findIndex(op => op[idField] === id);
      if (index !== -1) {
        state[operationType][index] = { ...state[operationType][index], ...updatedOperation };
      } else {
        console.error(`Operation with id ${id} not found in ${operationType}`);
      }
    },
    deleteGraphQLOperation: (state, action) => {
      const { operationType, id } = action.payload;
      // Determine the correct ID field based on operationType
      let idField = 'id';
      if (operationType === 'data_source_tables') {
        idField = 'table_id';
      } else if (operationType === 'table_mappings') {
        idField = 'table_mapping_id';
      } else if (operationType === 'column_mappings') {
        idField = 'column_mapping_id';
      }

      const initialLength = state[operationType].length;
      state[operationType] = state[operationType].filter(op => op[idField] !== id);
      if (state[operationType].length === initialLength) {
        console.error(`Operation with id ${id} not found in ${operationType}`);
      }
    },

    // Selected Operation Actions
    setSelectedOperation: (state, action) => {
      state.selectedOperation = action.payload; // { type, data }
    },
    clearSelectedOperation: (state) => {
      state.selectedOperation = null;
    },
    // Reset the form builder state to initial state
    resetFormBuilder: () => initialState,
  },
});

export const {
  setFormBuilderData,
  setSelectedItem,
  updateCanvasItems,
  addCanvasItem,
  removeCanvasItem,
  updateDataSourceTables,
  updateTableMappings,
  updateColumnMappings,
  addTableMapping,
  removeTableMapping,
  updateTableMapping,
  addColumnMapping,
  removeColumnMapping,
  updateColumnMapping,
  updateTabData,
  setSelectedDataAPIOption,
  reorderCanvasItems,
  moveItemToMainCanvas,
  moveItemToTab,
  resetFormBuilder,
  addGraphQLOperation,
  editGraphQLOperation,
  deleteGraphQLOperation,
  setSelectedOperation,
  clearSelectedOperation,
} = formBuilderSlice.actions;

export default formBuilderSlice.reducer;
