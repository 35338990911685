import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Accordion, AccordionSummary, AccordionDetails, Typography, List, ListItem, ListItemIcon, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { setActiveMenuItem } from '../../../redux/slices/menuSlice';

const MenuMuiData = ({ open, activeMenuItem, handleMenuItemClick }) => {
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState({});
    const [initialExpansionDone, setInitialExpansionDone] = useState(false);

    const menuItems = useSelector((state) => state.AccessManagement.menuData) || [];

    const transformSubMenu = useCallback((subMenu) => {
        if (subMenu.menuName === 'Menu') {
            return subMenu.subMenus ? subMenu.subMenus.map(transformSubMenu).flat() : [];
        }
        return {
            ...subMenu,
            url: subMenu.functionDetails?.url,
            title: subMenu.functionDetails?.functionName || subMenu.menuLineType,
            children: subMenu.subMenus ? subMenu.subMenus.map(transformSubMenu).flat() : [],
        };
    }, []);

    const organizeMenuTree = useCallback((items = []) => {
        return items.map((module) => ({
            ...module,
            children: module.menus
                ? module.menus.flatMap((menu) => {
                    if (menu.menuName === 'Menu') {
                        return menu.subMenus ? menu.subMenus.map(transformSubMenu).flat() : [];
                    }
                    return {
                        ...menu,
                        children: menu.subMenus ? menu.subMenus.map(transformSubMenu).flat() : [],
                    };
                })
                : [],
        }));
    }, [transformSubMenu]);

    const menuTree = organizeMenuTree(menuItems);

    const findItemPath = (items, targetId) => {
        for (let item of items) {
            const itemId = item.menuLineId || item.menuId || item.moduleId;
            if (itemId === targetId) {
                return [itemId];
            }
            if (item.children && item.children.length > 0) {
                const childPath = findItemPath(item.children, targetId);
                if (childPath.length > 0) {
                    return [itemId, ...childPath];
                }
            }
        }
        return [];
    };

    // Only run initial expansion once we have menuTree and activeMenuItem
    useEffect(() => {
        if (!initialExpansionDone && menuTree.length > 0 && activeMenuItem) {
            const path = findItemPath(menuTree, activeMenuItem);
            if (path && path.length > 0) {
                // Expand all parents of the active menu item
                setExpanded((prev) => {
                    const newExpanded = { ...prev };
                    path.forEach((id) => {
                        newExpanded[id] = true;
                    });
                    return newExpanded;
                });
                setInitialExpansionDone(true);
            }
        }
    }, [menuTree, activeMenuItem, initialExpansionDone]);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded((prev) => ({ ...prev, [panel]: isExpanded }));
    };

    const renderMenuItems = (items) => {
        return items.map((item) => {
            const itemId = item.menuLineId || item.menuId || item.moduleId;
            const hasChildren = item.children && item.children.length > 0;

            if (hasChildren) {
                return (
                    <Accordion
                        key={itemId}
                        expanded={!!expanded[itemId]}
                        onChange={handleAccordionChange(itemId)}
                    >
                        <AccordionSummary
                            expandIcon={open ? <ExpandMoreIcon /> : null}
                            aria-controls={`panel-${itemId}-content`}
                            id={`panel-${itemId}-header`}
                        >
                            <ListItemIcon className="iconimg">
                                {item.icon && <img src={item.icon} alt="" style={{ width: '24px', height: '24px' }} />}
                            </ListItemIcon>
                            {open && <Typography variant="subtitle1">{item.moduleName || item.menuName}</Typography>}
                        </AccordionSummary>
                        <AccordionDetails>
                            <List>{renderMenuItems(item.children)}</List>
                        </AccordionDetails>
                    </Accordion>
                );
            } else {
                // Leaf node - clicking this sets activeMenuItem
                return (
                    <ListItem
                        button
                        component={Link}
                        to={item.url || '#'}
                        key={itemId}
                        onClick={() => {
                            dispatch(setActiveMenuItem(itemId));
                            handleMenuItemClick(item);
                        }}
                        selected={item.menuLineId === activeMenuItem}
                    >
                        <ListItemIcon className="iconimg">
                            {item.icon && <img src={item.icon} alt="" style={{ width: '24px', height: '24px' }} />}
                        </ListItemIcon>
                        {open && <Typography variant="subtitle1">{item.title}</Typography>}
                    </ListItem>
                );
            }
        });
    };

    return (
        <Box className="menu">
            {renderMenuItems(menuTree)}
        </Box>
    );
};

export default MenuMuiData;
