import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setUnreadCount } from '../../../redux/slices/notificationsSlice';
import useNotificationService from '../../../services/useNotificationService';
import { Box, Typography, Button, Menu, MenuItem, CircularProgress } from '@mui/material';
import HtmlMessageDisplay from '../../fwk/HTML/HtmlMessageDisplay';

function NotificationIcon() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem('user'));
    const userId = user.user_id;

    // State for dropdown menu and notification data
    const [anchorEl, setAnchorEl] = useState(null);
    const { loading, unreadNotifications, unreadCount } = useNotificationService(userId);

    // Redux state for unread count
    // const notificationCount = useSelector((state) => state.notifications.unreadCount);

    // Update unread count and local notifications list in Redux
    useEffect(() => {
        dispatch(setUnreadCount(unreadCount));
    }, [unreadCount, dispatch]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleNotificationClick = async (notificationId) => {
        try {

            // Remove the notification from the local list and update unread count
            const updatedNotifications = unreadNotifications.filter((notification) => notification.notification_id !== notificationId);
            dispatch(setUnreadCount(updatedNotifications.length));
            setAnchorEl(null);

            // Navigate to the notification view
            navigate(`/app/notificationview/${notificationId}`);
        } catch (error) {
            console.error('Failed to mark notification as read:', error);
        }
    };

    const handleViewAll = () => {
        handleClose();
        navigate('/app/notifications');
    };

    return (
        <div>
            <div className='notification icon' onClick={handleClick}>
                <img src='/LightTheme/notifications.svg' alt='Notifications' />
                <span>{loading ? '...' : unreadCount || 0}</span>
            </div>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{ style: { width: '300px' } }}
            >
                {loading ? (
                    <Box display="flex" justifyContent="center" padding={2}>
                        <CircularProgress size={24} />
                    </Box>
                ) : unreadNotifications.length > 0 ? (
                    unreadNotifications.slice(0, 5).map((notification) => (
                        <MenuItem
                            key={notification.notification_id}
                            onClick={() => handleNotificationClick(notification.notification_id)}
                        >
                            {/* <Typography variant="body2">{notification.message}</Typography> */}
                            <HtmlMessageDisplay
                                message={notification.message}
                                variant={'body'}
                            />
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem disabled>
                        <Typography variant="body2">No unread notifications</Typography>
                    </MenuItem>
                )}
                <Box display="flex" justifyContent="center" padding={1}>
                    <Button onClick={handleViewAll} color="primary">
                        View All Notifications
                    </Button>
                </Box>
            </Menu>
        </div>
    );
}

export default NotificationIcon;
