import React from 'react';
import { Typography, Box } from '@mui/material';

/**
 * HtmlMessageDisplay - A reusable component to display HTML content safely.
 * @param {string} message - The HTML message content to display.
 * @param {object} style - Additional styles to apply (optional).
 * @returns {JSX.Element}
 */
const HtmlMessageDisplay = ({ message, style = {}, variant }) => {
    return (
        <Box mt={2} style={style}>
            <Typography
                width={'100%'}
                component="div"
                variant={{variant}}
                style={{ whiteSpace: 'pre-line' }}
                dangerouslySetInnerHTML={{ __html: message }}
            />
        </Box>
    );
};

export default HtmlMessageDisplay;
