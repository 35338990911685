// src/router/AuthRouter.js

import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import PageLoader from '../components/PageLoader';

// Lazy-loaded authentication pages
const LoginPage = lazy(() => import('../modules/AccessManagement/Authentication/components/LoginPage'));

const AuthRouter = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        <Route path="/login" element={<LoginPage />} />

        {/* Fallback Route for Auth */}
        <Route path="*" element={<LoginPage />} />
      </Routes>
    </Suspense>
  );
};

export default AuthRouter;
