import { gql } from '@apollo/client';

export const NOTIFICATION_FIELDS = gql`
  fragment NotificationFields on Notification {
    notification_id
    user_id
    sender_id
    module_code
    entity_id
    entity_type
    action_url
    message
    status
    action_required
    action_type
    priority
  }
`;
