// sentinel.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthRouter from './router/AuthRouter';
import { checkTokenExpiry } from './middleware/tokenMiddleware';
import DefaultApp from './DefaultApp';

const Sentinel = () => {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  useEffect(() => {
    if (token && checkTokenExpiry(token)) {
      // Token is invalid or expired; log out user
      localStorage.setItem('isLoggedIn', false);
      navigate('/login');
    }
  }, [token, navigate]);

  return token ? <DefaultApp /> : <AuthRouter />;
};

export default Sentinel;
