// src/components/security/ProtectedRoute.js

import React from 'react';
import { Navigate, useLocation, matchPath } from 'react-router-dom';
import { useSelector } from 'react-redux';

/**
 * Helper function to recursively extract all non-empty URLs from menuData.
 * @param {Array} menuData - The menu data array from Redux state.
 * @returns {Array} - An array of allowed URL strings.
 */
const extractAllowedUrls = (menuData) => {
  const urls = [];

  /**
   * Recursive function to traverse menuData and collect URLs.
   * @param {Array} items - Current level of menu items.
   */
  const traverse = (items) => {
    items.forEach((item) => {
      // Collect the module's URL if it's not empty
      if (item.url && item.url.trim() !== '') {
        urls.push(item.url);
      }

      // Traverse through menus if they exist
      if (item.menus && Array.isArray(item.menus)) {
        traverse(item.menus);
      }

      // Traverse through subMenus if they exist
      if (item.subMenus && Array.isArray(item.subMenus)) {
        traverse(item.subMenus);
      }
    });
  };

  traverse(menuData);
  return urls;
};

/**
 * ProtectedRoute Component
 * 
 * This component ensures that the user is both authenticated and authorized
 * to access the requested route based on their menuData.
 * 
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components to render if authorized.
 * @returns {React.ReactNode} - The rendered child components or a redirection.
 */
const ProtectedRoute = ({ children }) => {
  // Retrieve authentication and menu data from localStorage
  const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
  const menuData = JSON.parse(localStorage.getItem('menuData'));
  const location = useLocation();

  // Define a whitelist of paths that don't require protection
  const whitelist = ['/login', '/unauthorized'];

  // 1. Authentication Check
  if (!isLoggedIn) {
    // Allow access to whitelist paths even if not logged in
    const isWhitelisted = whitelist.some((path) => matchPath({ path, end: true }, location.pathname));
    if (isWhitelisted) {
      return children;
    }

    // Redirect unauthenticated users to the login page
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  // 2. Authorization Check
  if (!menuData || !Array.isArray(menuData)) {
    // If menuData is unavailable or not an array, treat as unauthorized
    return <Navigate to="/unauthorized" replace />;
  }

  // Extract all allowed URLs from menuData
  const allowedUrls = extractAllowedUrls(menuData);


  // Get the current pathname
  const currentPath = location.pathname;

  // Check if the current path is in the whitelist
  const isWhitelisted = whitelist.some((path) => matchPath({ path, end: true }, currentPath));
  if (isWhitelisted) {
    return children;
  }

  // Determine if the current path is allowed
  const isPathAllowed = allowedUrls.some((allowedPath) => {
    if (allowedPath === '/') {
      // For root path, require exact match
      const match = matchPath({ path: allowedPath, end: true }, currentPath);
      return match !== null;
    } else {
      // For other paths, allow partial matches (nested routes)
      const match = matchPath({ path: allowedPath, end: false }, currentPath);      return match !== null;
    }
  });

  // if (!isPathAllowed) {
  //   // Redirect unauthorized users to the Unauthorized page
  //   console.log(`Access to '${currentPath}' is not allowed. Redirecting to /unauthorized`);
  //   return <Navigate to="/unauthorized" replace />;
  // }

  // 3. Render Child Components if Authorized
  return children;
};

export default ProtectedRoute;
