// src/components/Layout/MainLayout.js

import React from 'react';
import Navbar from './Navbar/Navbar';
import MenuMui from './Menu/MenuMui';
import { Outlet } from 'react-router-dom';
import '../../styles/global.scss'; // Ensure global styles are imported

const MainLayout = () => {
  return (
    <div className="main">
      <Navbar />
      <div className="container">
        <div className="menuContainer">
          <MenuMui />
        </div>
        <div className="contentContainer">
          <Outlet /> {/* Renders the matched child route */}
        </div>
      </div>
      {/* Add Footer or other global components if needed */}
    </div>
  );
};

export default MainLayout;
