import { useQuery, useMutation } from '@apollo/client';
import { notificationQueries } from '../modules/ApplicationManagement/Notifications/graphql/appNotificationQueries';

const useNotificationService = (userId) => {
    const { loading, error, data } = useQuery(notificationQueries.GET_ALL_UNREAD_NOTIFICATIONS, {
        variables: { user_id: userId },
        fetchPolicy: 'network-only',
    });

    const unreadNotifications = data?.getAllUnreadNotifications || [];
    const unreadCount = unreadNotifications.length;

    return {
        loading,
        error,
        unreadNotifications,
        unreadCount,
    };
};

export default useNotificationService;
