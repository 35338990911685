import { gql } from '@apollo/client';
import { NOTIFICATION_FIELDS } from './appNotificationFragements';

export const notificationQueries = {
    GET_ALL_NOTIFICATIONS: gql`
        query getAllNotificationsByUser($user_id: Int!) {
            getNotificationsByUser(user_id: $user_id) {
                ...NotificationFields
            }
        }
        ${NOTIFICATION_FIELDS}
    `,
        GET_ALL_UNREAD_NOTIFICATIONS: gql`
        query getAllUnreadNotifications($user_id: Int!) {
            getAllUnreadNotifications(user_id: $user_id) {
                ...NotificationFields
            }
        }
        ${NOTIFICATION_FIELDS}
    `,
    GET_UNREAD_NOTIFICATION_COUNT: gql`
    query getUnreadNotificationCount($user_id: Int!) {
        getUnreadNotificationCount(user_id: $user_id)
    }
    `,
    GET_NOTIFICATION_BY_ID: gql`
        query getNotificationById($notification_id: Int!) {
            getNotificationById(notification_id: $notification_id) {
                ...NotificationFields
            }
        }
        ${NOTIFICATION_FIELDS}
    `,
};
