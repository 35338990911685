// DefaultApp.js
import React, { Suspense } from 'react';
import { ApolloProvider } from '@apollo/client';
import { client } from './graphql/client';

import PageLoader from './components/PageLoader';
import AppHome from './components/Layout/AppHome';

export default function DefaultApp() {
  return (
    <ApolloProvider client={client}>
      <Suspense fallback={<PageLoader />}>
        <AppHome />
      </Suspense>
    </ApolloProvider>
  );
}
