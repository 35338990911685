import React from 'react';
import '../styles/navbar.scss';
import ProfileMenu from './ProfileMenu';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import NotificationIcon from './NotificationIcon';

function Navbar() {
    const navigate = useNavigate();

    const handleNakshaClick = () => {
        navigate('/formslist');
    };

    const handleSettingsClick = () => {
        navigate('/settings');
    };

    const LogoImage = styled('img')({
        width: '45px',
    });

    return (
        <div className='navbar'>
            <div className='logo'>
                <LogoImage src='/LightTheme/akshaLogo.png' alt='Chakra ERP' />
            </div>
            <div className='icons'>
                <img src='/LightTheme/naksha.png' alt='' className='icon' onClick={handleNakshaClick} />
                
                {/* Use the NotificationIcon component */}
                <NotificationIcon />
                
                <img src='/LightTheme/settings.svg' alt='' className='icon' onClick={handleSettingsClick} />
                <div>
                    <ProfileMenu />
                </div>
            </div>
        </div>
    );
}

export default Navbar;
