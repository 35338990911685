import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
    background: {
      default: '#ffffff',
      paper: '#f5f5f5',
    },
    text: {
      primary: '#000000',
      secondary: '#5f6368',
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none !important',
          fontWeight: 'bold !important',
          '&:hover': {
            backgroundColor: 'rgba(0, 123, 255, 0.1)', // Light blue background on hover
          },
        },
      },
    },    
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '12px', // Adjust the font size of buttons
          padding: '3px 10px', // Adjust the padding of buttons (top/bottom and left/right)
          fontWeight: 1000,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)', // Color of the underline when the input is not focused
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: '2px solid rgba(0, 0, 0, 0.87)', // Color of the underline when the input is hovered
          },
          '&:after': {
            borderBottom: '2px solid rgba(0, 0, 0, 0.87)', // Color of the underline when the input is focused
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '14px',
        },
        // input: {
        //   padding: '0 !important', // Override padding for input
        // },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          width: '70%',
          '&::after': {
            borderBottom: '5px solid #1976d2',
          },
        },
        input: {
          padding: '2px !important', // Override padding for MuiInput input
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          width: '70%',
          fontSize: '14px',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          width: '100%',
          fontSize: '14px',
          borderStyle: 'none'
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: '8px !important', // Adjust the margin-bottom of MuiTextField
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginBottom: '8px !important', // Adjust the margin-bottom of MuiTextField
        },
      },
    },
  },
});

export default lightTheme;
