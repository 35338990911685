// App.js
import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';

import PageLoader from './components/PageLoader';
import { ThemeProvider } from '@mui/material/styles';
import LightTheme from './themes/lightTheme';
// import DarkTheme from './themes/darkTheme';
import StatusDisplay from './components/status/StatusDisplay';

// Your sentinel that conditionally renders Auth or the main App
import Sentinel from './sentinel';

import './middleware/apolloInterceptor';
import ErrorBoundary from './components/Errors/ErrorBoundary';

function App() {
  const [currentTheme, setCurrentTheme] = useState(LightTheme);

  return (
    <Provider store={store}>
      <PersistGate loading={<PageLoader />} persistor={persistor}>
        <ThemeProvider theme={currentTheme}>
          <BrowserRouter>
            {/* Some global components */}
            <StatusDisplay />
            <suspense fallback={<PageLoader />} />

            {/* Render sentinel or direct routes. 
                The sentinel handles login checks and shows either
                DefaultApp (main app) or AuthRouter (login routes). 
            */}
            <ErrorBoundary>
              <Sentinel />
            </ErrorBoundary>
          </BrowserRouter>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
