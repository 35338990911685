import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuMuiData from './MenuMuiData';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';  // <-- import useLocation
import '../styles/menu.scss';

const drawerWidth = 280;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  })
);

export default function MiniDrawer() {
  const theme = useTheme();
  const location = useLocation();  // <-- track current path
  const isSmallOrTabletScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = React.useState(!isSmallOrTabletScreen);
  const [collapsed, setCollapsed] = React.useState(false);

  const activeMenuItem = useSelector((state) => state.menu.activeMenuItem);

  // Toggle open -> closed
  const handleDrawerOpen = () => {
    setOpen(true);
    setCollapsed(false);
  };

  // Toggle closed -> open
  const handleDrawerClose = () => {
    setOpen(false);
    setCollapsed(true);
  };

  // 1. Adjust drawer state based on screen size changes.
  React.useEffect(() => {
    setOpen(!isSmallOrTabletScreen);
    setCollapsed(!isSmallOrTabletScreen);
  }, [isSmallOrTabletScreen]);

  // 2. ALSO adjust drawer if user is on /naksha or /naksha/anything
  React.useEffect(() => {
    const isNakshaRoute = /^\/naksha(\/.*)?$/.test(location.pathname);
    if (isNakshaRoute) {
      // Force collapse
      setOpen(false);
      setCollapsed(true);
    } else {
      // Force expand (or you can omit if you want manual control after first expand)
      setOpen(!isSmallOrTabletScreen);
      setCollapsed(!isSmallOrTabletScreen);
    }
  }, [location.pathname, isSmallOrTabletScreen]);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleMenuItemClick = (item) => {
    handleScrollToTop();
    if (isSmallOrTabletScreen) {
      setOpen(false);
      setCollapsed(true);
    }
  };

  return (
    <Box className="menu" sx={{ display: 'flex' }}>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            edge="start"
            style={open ? { padding: '8px' } : { padding: '0px', paddingRight: '14px' }}
          >
            {open ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />

        <MenuMuiData
          open={open}
          setOpen={setOpen}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          activeMenuItem={activeMenuItem}
          handleMenuItemClick={handleMenuItemClick}
        />
        <Divider />
      </Drawer>
    </Box>
  );
}
