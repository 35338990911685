import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { useMutation } from '@apollo/client';
import { userMutations } from '../../../modules/AccessManagement/Users/graphql/amUserMutations';
import { logoutUser } from '../../../modules/AccessManagement/redux/amActions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';


export default function ProfileMenu() {
    const [logoutUserMutation] = useMutation(userMutations.LOGOUT_USER_MUTATION);
   // const username = useSelector((state) => state.accessManagement.username);
    // Retrieve the user data from localStorage and parse it
    const user = JSON.parse(localStorage.getItem('user'));
    
    // Access the username from the user object
    const username = user ? user.username : '';

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await logoutUserMutation();
            await dispatch(logoutUser());
            navigate('/');
        } catch (error) {
            console.error('Logout failed:', error);
            // Handle logout error (e.g., show error message)
        }
    };

    return (
        <PopupState variant="popover" popupId="user-menu">
            {(popupState) => (
                <>
                    {/* Container for avatar and name */}
                    <div
                        style={{ display: 'flex', alignItems: 'center', gap: '8px', cursor: 'pointer' }}
                        {...bindTrigger(popupState)}
                    >
                        <Avatar
                            alt="User Avatar"
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1200px-React-icon.svg.png"
                            sx={{ width: 32, height: 32 }}
                        />
                        <Typography variant="subtitle1">{username}</Typography>
                    </div>

                    {/* Menu with menu items */}
                    <Menu {...bindMenu(popupState)}>
                        <MenuItem onClick={popupState.close}>Profile</MenuItem>
                        <MenuItem onClick={popupState.close}>My account</MenuItem>
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                </>
            )}
        </PopupState>
    );
}
