import React from 'react';
import AppRouter from '../../router/AppRouter';
import '../../styles/global.scss';
import AuthRouter from '../../router/AuthRouter';

const AppHome = () => {
  // State to manage the visibility of menuContainer
  // const [isMenuVisible, setMenuVisible] = useState(true);
  const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));

  // Function to toggle the visibility of menuContainer
  // const toggleMenuVisibility = () => {
  //   setMenuVisible((prevState) => !prevState);
  // };

  if (isLoggedIn) {
    return (
      <div className="main">
        <div className="container">
          <div className="contentContainer">
            <AppRouter />
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div>
        <AuthRouter />
      </div>
    )
  }
};

export default AppHome;
